<template>
  <div class="join_us">
    <headerNav pathname="加入我们" />
    <text-common
      title="加入我们"
      :img="img"
    />
    <div class="join_us_content_bg">
      <!-- <div class="join_us_content">
        <theme title="虚位以待" />
        <table>
          <tr style="height: 42px">
            <th style="width: 191px">岗位类型</th>
            <th style="width: 328px">培养方向</th>
            <th style="width: 255px">专业要求</th>
            <th style="width: 197px">工作地点</th>
          </tr>
          <tr style="height: 106px">
            <td rowspan="2">营运物流岗（50人）</td>
            <td>网点管理、营销专员、客户维护、客户跟踪</td>
            <td
              rowspan="2"
              style="padding: 0 40px; box-sizing: border-box"
            >
              专业不限，物流、营销、经管相关专业优先
            </td>
            <td rowspan="2">上海、浙江、江苏</td>
          </tr>
          <tr style="height: 83px">
            <td>场站收货、货量配载、车辆调度、产品外发</td>
          </tr>
          <tr style="height: 146px">
            <td>财务助理岗（20人）</td>
            <td>资金、结算、审核、核算、税务等</td>
            <td>会计学，财务管理相关专业</td>
            <td>上海市青浦区</td>
          </tr>
          <tr style="height: 167px">
            <td>人力行政岗（5人）</td>
            <td style="padding: 0 25px; box-sizing: border-box">
              大区助理、职能助理、数据统计分析、人力资源等
            </td>
            <td style="padding: 0 30px; box-sizing: border-box">
              专业不限，人力、行政、统计相关专业优先
            </td>
            <td>上海市青浦区</td>
          </tr>
        </table>
      </div> -->
      <!-- 普升通道 -->
      <!-- <div class="promotion">
        <theme title="普升通道" />
        <div class="promotion_bg">
          <div class="promotion_text_bg">
            <div class="major">
              <span>专业通道：</span>
              初级专员-中级专员-高级专员-首席专家
            </div>
            <div class="administration">
              <span>管理通道：</span>
              员工-经理-高级经理&nbsp;-总监&nbsp;-高级总监-副总裁
            </div>
          </div>
          <img
            width="203px"
            height="195px"
            src="../assets/joinUs/promotion_img.png"
            alt=""
          />
        </div>
      </div> -->
      <div class="workTime">
        <theme title="中骁生活" />
        <div class="pay">
          <img
            width="596px"
            src="../assets/joinUs/zhongxiaolife.png"
            alt=""
          />
          <!-- <ul class="welfare_text">
            <li
              v-for="(item, index) in welfareList"
              :key="index"
              :style="{ fontWeight: item.fontWeight }"
            >
              {{ item.title }}
            </li>
          </ul> -->
        </div>
      </div>
      <!-- 薪酬福利 -->
      <div class="pay_bg">
        <theme title="薪酬福利" />
        <div class="pay">
          <img
            width="289px"
            height="203px"
            src="../assets/joinUs/pay_img.png"
            alt=""
          />
          <ul class="welfare_text">
            <li
              v-for="(item, index) in welfareList"
              :key="index"
              :style="{ fontWeight: item.fontWeight }"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
      <!-- 面试流程 -->
      <!-- <div class="interview_bg">
        <theme title="面试流程" />
        <div class="interview">
          网申——参加宣讲会/双选会——行为面试（无领导小组讨论/一分钟演讲）——BEI面谈——签约面谈——发放Offer。
        </div>
      </div> -->
      <!-- 加入我们 -->
      <div class="join_us_bg">
        <theme title="加入我们" />
        <div class="join_us_text">
          <div class="join_us_item">
            <div
              v-for="(item, index) in joinUsList"
              :key="index"
            >
              <img
                :width="item.width"
                :height="item.height"
                :src="item.img"
                alt=""
              />
              <span>{{ item.title }}</span>
            </div>
          </div>

          <div class="img">
            <img
              width="136px"
              src="../assets/joinUs/zx.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <bottom-navigation />
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: require("../assets/joinUs/joinUs.png"),
      welfareList: [
        {
          title: "薪资构成：",
          fontWeight: 1000,

        },
        {
          title: "1.固定薪资（基本薪资+食宿补贴+全勤奖）：试用期2个月"
        },
        {
          title: "2.浮动薪资：季度绩效奖金（1000元）/货量提成/业绩提成（具体形式以岗位区分）"
        },
        {
          title: "3.福利待遇：宽带薪酬、中秋补贴、端午补贴、高温补贴、返岗激励、首次报道交通费报销、员工带薪培训、导师激励奖金、酒店住宿报销"
        },
      ],
      joinUsList: [
        {
          img: require("../assets/joinUs/mailbox_img.png"),
          title: "企业邮箱：",
          width: "19px",
          height: "14px"
        },
        {
          img: require("../assets/joinUs/contact_img.png"),
          title: "联系人：易经理",
          width: "19px",
          height: "20px"
        },
        {
          img: require("../assets/joinUs/phone_img.png"),
          title: "联系电话：19101601382",
          width: "21px",
          height: "20px"

        },
        {
          img: require("../assets/joinUs/wx_img.png"),
          title: "官方微信：19101601382",
          width: "21px",
          height: "18px"
        },
        {
          img: require("../assets/joinUs/location_img.png"),
          title: "面试地点：浙江省义乌市稠江街道东河南街388号东宇智能物流园综合楼2楼202室",
          width: "16px",
          height: "20px"
        },
        
      ]

    }
  }

}
</script>
<style lang="less" scoped>
.join_us {
  .join_us_content_bg {
    background: #f8f8f8;
    width: 100%;
    .join_us_content {
      padding: 80px 0 50px 0;
      box-sizing: border-box;
    }
  }
  table {
    border-collapse: collapse;
    text-align: center;
    margin: 54px auto 0 auto;
  }
  th {
    color: #caced9;
    background: #182d5d;
    font-size: 18px;
    font-weight: 400;
  }
  td {
    color: rgba(0, 0, 0, 0.85);
  }
  table,
  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  // 普升通道

  .promotion {
    background: #f0f2f5;
    padding: 80px 0 0 0;
    box-sizing: border-box;
    .promotion_bg {
      display: flex;
      justify-content: center;
      margin: 64px 0 0 0;
      .promotion_text_bg {
        width: 430px;
        height: 108px;
        margin-right: 158px;
        opacity: 0.85;
        transform: translateY(60px);
        .administration {
          margin-top: 35px;
        }
        span {
          display: inline-block;
          font-weight: bold;
        }
        .major::before {
          content: "";
          display: inline-block;
          width: 18px;
          height: 14px;
          background: url(../assets/joinUs/major_img.png);
          background-size: 18px 14px;
          margin-right: 9px;
          transform: translateY(2px);
        }

        .administration::before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 14px;
          background: url(../assets/joinUs/administration_img.png);
          background-size: 16px 14px;
          margin-right: 9px;
          // transform: translateY(2px);
        }
      }
    }
  }
  .pay_bg {
    background: #f0f2f5;
    padding: 71px 0 98px 0;
    box-sizing: border-box;
    .pay {
      display: flex;
      justify-content: center;
      margin-top: 92px;
      .welfare_text {
        width: 596px;
        height: 200px;
        margin-left: 108px;
        transform: translateY(10px);
        line-height: 35px;
      }
    }
  }
  .workTime {
    background: #fff;
    padding: 71px 0 98px 0;
    box-sizing: border-box;
    .pay {
      display: flex;
      justify-content: center;
      margin-top: 92px;

      img {
        width: 60%;
      }
    }
  }
  // 面试流程
  .interview_bg {
    width: 100%;
    height: 246px;
    padding: 80px 0 71px 0;
    box-sizing: border-box;
    text-align: center;
    .interview {
      margin-top: 40px;
      color: rgb(0, 0, 0, 0.85);
    }
  }
  .join_us_bg {
    width: 100%;
    background: #fff;
    padding: 80px 0 120px 0;
    box-sizing: border-box;
    // .join_us {
    .join_us_text {
      display: flex;
      justify-content: center;
      margin-top: 87px;
      .img {
        width: 136px;
        height: 136px;
        background: #505050;
        transform: translateY(50px);
      }
      .join_us_item {
        span {
          display: inline-block;
          line-height: 40px;
          margin-right: 62px;
          opacity: 0.85;
          //   width: 622px;
          //   height: 252px;
        }
        img {
          transform: translateY(2px);
          margin-right: 14px;
        }
      }
    }
    // }
  }
}
</style>